import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import umusa1 from "./../images/umusaseiyaku1.jpg";
// import umusa2 from "./../images/umusa2.jpg";
import umusa2_1 from "./../images/umusa2-1.jpg";
import hamamoto1 from "./../images/hamamoto1.jpg";
import hamamoto2 from "./../images/hamamoto2.jpg";
import hamamoto3 from "./../images/hamamoto3.jpg";
import hamamoto4 from "./../images/hamamoto4.jpg";
import ryuguzyo1 from "./../images/ryugujyomae1.jpg";
import ryuguzyo2 from "./../images/ryugujyomae6.jpg";
import ryuguzyo3 from "./../images/ryugujyomae7.jpg";
import ryuguzyo4 from "./../images/ryugujyomae8.jpg";
import yamakawags1 from "./../images/yamakwagsyoko1.jpg";
import yamakawags2 from "./../images/yamakwagsyoko6.jpg";
import yamakawags3 from "./../images/yamakwagsyoko7.jpg";
import yamakawags4 from "./../images/yamakwagsyoko8.jpg";
import nanmamui1 from "./../images/nanmamui1.jpg";
import nanmamui2 from "./../images/nanmamui2.jpg";
import nanmamui3 from "./../images/nanmamui3.jpg";
import nanmamui4 from "./../images/nanmamui4.jpg";
import nanmamui5 from "./../images/nanmamui5.jpg";
import yaga1 from "./../images/yaga1.jpg";
import yaga2 from "./../images/yaga2.jpg";
import yaga3 from "./../images/yaga3.jpg";
import yaga4 from "./../images/yaga4.jpg";
import yaga5 from "./../images/yaga5.jpg";

const Property = () => {
  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div>

          {/* ここまで */}
          {/* 物件情報ここから2 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        御成約済み宇茂佐に同等レベルの新土地
                        目の前プライベートビーチ
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2_1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        {/* <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide> */}
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      <div className="comment-box">
                        <p className="">
                          庭からそのまま海へアプローチ！ジェットスキーも釣りもご自分の”庭”からできます！
                          名護の繁華街までも近く、周辺の施設も充実しております。
                        </p>
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市字宇茂佐</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間15分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>
                              簿：530㎡ （約160.32坪)
                              実測：531.02㎡（約160.63坪）
                            </p>
                          </div>
                        </div>
                      </div>
                      <a
                        href="/umusa2"
                        class="btn btn-accent btn-full btn-rounded"
                      >
                        詳細はこちら
                      </a>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}

          {/* 物件情報ここから3 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        沖縄の松島 絶景 プライベートビーチ 船舶停留可
                        マリンアクティビティ◎
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={nanmamui1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={nanmamui2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={nanmamui3}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={nanmamui4}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={nanmamui5}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ホテル</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      <div className="comment-box">
                        <p className="">
                          内海に面しているため、穏やかな海を前にゆったりとした時間を楽しめます。
                          バリのようなアジアンテイストリゾート物件です。
                        </p>
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市字饒平名湧増</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から約1時間30分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>24,739㎡(7,483.54坪)</p>
                          </div>
                        </div>
                      </div>
                      <a
                        href="/nanmamui"
                        class="btn btn-accent btn-full btn-rounded"
                      >
                        詳細はこちら
                      </a>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}

          {/* 物件情報ここから4 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        プライベートビーチ 白砂 透明度の高い東シナ海に面した絶景
                        古宇利島一望 分割相談可
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img src={yaga1} alt="banner" className="img-slide" />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img src={yaga2} alt="banner" className="img-slide" />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img src={yaga3} alt="banner" className="img-slide" />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img src={yaga4} alt="banner" className="img-slide" />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img src={yaga5} alt="banner" className="img-slide" />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・ホテル</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      <div className="comment-box">
                        <p className="">
                          3つのプライベートビーチ付き物件です。ビーチからは古宇利島が望めます。
                          外海特有のオーシャンブルーを独り占めできます。
                        </p>
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市屋我</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間30分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>18,740㎡（5,668.85坪）</p>
                          </div>
                        </div>
                      </div>
                      <a
                        href="/yaga"
                        class="btn btn-accent btn-full btn-rounded"
                      >
                        詳細はこちら
                      </a>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから5 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        58号線沿いホテルマハイナ/美ら海水族館続く
                        周辺高稼働率ヴィラ複数新設
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={ryuguzyo1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={ryuguzyo2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={ryuguzyo3}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={ryuguzyo4}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">店舗付ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      <div className="comment-box">
                        <p className="">
                          周辺にはスターバックスや美らテラスなど商圏のあるエリアに位置しております。
                          店舗付きヴィラの用途には最適です。
                        </p>
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県国頭郡本部町山川西原</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間20分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>5,259.08㎡(1,590.87坪)</p>
                          </div>
                        </div>
                      </div>
                      <a
                        href="/ryuguzyo"
                        class="btn btn-accent btn-full btn-rounded"
                      >
                        詳細はこちら
                      </a>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから6 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        オーシャンビュー 2026年電柱地中埋設完了予定
                        マンション/リゾートホテル分割相談可 店舗付きヴィラ13棟
                        想定利回り 12％越
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <img
                            src={yamakawags1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={yamakawags2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={yamakawags3}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={yamakawags4}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">ホテル</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">店舗付ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          {/* <FaYenSign /> */}
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      <div className="comment-box">
                        <p className="">
                          高台から望む海は絶景です。日中と夕方で表情を変えるのも魅力の一つです。
                          目の前は美ら海水族館までの通りに面しており集客性、商圏に優れております。
                        </p>
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県国頭郡字山川</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間40分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>5,325.83㎡(1,617.11坪)</p>
                          </div>
                        </div>
                      </div>
                      <a
                        href="/yamakawags"
                        class="btn btn-accent btn-full btn-rounded"
                      >
                        詳細はこちら
                      </a>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}

          {/* 物件情報ここから7 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        180度アラウンドオーシャンビュー
                        （本部大橋→瀬底大橋→瀬底島→ヒルトンホテル（花火）→
                        水納島→絶景夕日→伊江島→本部げんき村）3棟現在建築中
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={hamamoto1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={hamamoto2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={hamamoto3}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={hamamoto4}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      <div className="comment-box">
                        <p className="">
                          目の前の海からは本部大橋、瀬底島、伊江島と沖縄中北部の絶景を独り占め！毎週はテラスから花火も見れます！
                        </p>
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県国頭郡本部町字浜元浜元原</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から約1時間30分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>
                              A 191.81㎡(58.02) B 171,78㎡(51.96坪) C
                              138.00㎡(41坪)
                            </p>
                          </div>
                        </div>
                      </div>

                      <a
                        href="/hamamoto"
                        class="btn btn-accent btn-full btn-rounded"
                      >
                        詳細はこちら
                      </a>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
        </div>
      </section>
    </>
  );
};

export default Property;
